/* You can add global styles to this file, and also import other style files */
iframe {
  width: 1330px !important;
  height: 600px !important;
}

body {
  background: url("./assets/images/Start_LoginMainBg2560x1440.jpg") center top
    no-repeat #202020;
  min-height: 100%;
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}
